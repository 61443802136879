import {
    FooterButtonsContext,
    FooterButtonsProvider,
    useFooterButtons,
} from "@/components/DataUploads/Wizard/NewTable/components/FooterButtonsContext";
// import { UCLayoutWithSteps, UCLayoutWithStepsInner, } from "@/components/DataUploads/Wizard/NewTable/components/UCLayoutWithSteps";
import { noop } from "lodash/fp";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { map, pick } from "lodash/fp";
import RemoteLayout from "@/components/PageLayout/RemoteLayout";
import { useConfirmModal } from "@/modules/modal";
import {
    Alert,
    ButtonMenu,
    Forms,
    Tabs,
    UnityLayout,
    UnitySteps,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";

export const useStepperProps = ({ steps, initialStepIndex = 0 }) => {
    const stepsRef = useRef(steps);
    stepsRef.current = steps;
    // TODO: currentStepKey
    const [currentStepIndex, setCurrentStepIndex] = useState(initialStepIndex);
    const currentStep = steps[currentStepIndex];
    const nextStep = useCallback(
        () =>
            setCurrentStepIndex(index =>
                Math.min(index + 1, stepsRef.current.length - 1),
            ),
        [],
    );
    const prevStep = useCallback(
        () => setCurrentStepIndex(index => Math.max(index - 1, 0)),
        [],
    );
    const goToStep = useCallback(({ key }) => {
        const index = stepsRef.current.findIndex(step => step.key === key);
        setCurrentStepIndex(index);
    }, []);

    return useMemo(
        () => ({
            steps,
            currentStep,
            currentStepIndex,
            nextStep,
            prevStep,
            isFirstStep: currentStepIndex === 0,
            isLastStep: currentStepIndex === steps.length - 1,
            goToStep,
        }),
        [currentStep, currentStepIndex, goToStep, nextStep, prevStep, steps],
    );
};

export const useSteppedForm = ({
    stepperProps,
    stepFormProps: {
        initialValues, // TODO: treat them as reactive in tab variant?
        reactiveInitialValues, // on tab change needs to be saved and reinitialized
        allValues, // when rendered as steps, values are collected here
        setAllValues,
        onSubmit,
        getStepButtons,
    },
    isTabVariant,
    renderButtons,
}) => {
    const form = Forms.useForm({
        // Initial effect run discarded, needs to have initialValues
        // initialValues: isTabVariant ? {} : allValues,
        initialValues: isTabVariant ? reactiveInitialValues : allValues,
        onSubmit: ({ values, args: [action] }) => {
            let newValues;
            if (isTabVariant) {
                newValues = values;
            } else {
                newValues = { ...allValues, ...values };
                setAllValues(newValues);
            }

            if (typeof action === "function") {
                return action({ values: newValues });
            } else if (stepperProps.isLastStep || isTabVariant) {
                return onSubmit({ values: newValues });
            } else {
                stepperProps.nextStep();
            }
        },
    });
    const { formId, submit, setInitialValues } = form;
    const setInitialValuesStatic = useCurrentHandler(setInitialValues);
    useEffect(() => {
        if (reactiveInitialValues && isTabVariant) {
            // Initially missed, lost in Forms - initialValues used instead
            console.log("%c[useSteppedForm.init eff]", "color:lime", {
                isTabVariant,
                reactiveInitialValues,
            });
            setInitialValuesStatic(reactiveInitialValues);
        }
    }, [isTabVariant, reactiveInitialValues, setInitialValuesStatic]);
    // console.log("[useSteppedForm.rndr]", { isTabVariant, form, reactiveInitialValues, allValues, });

    const buttons = useMemo(
        () =>
            getStepButtons({
                stepperProps,
                formId,
                submit,
                isTabVariant,
            }),
        [formId, getStepButtons, isTabVariant, stepperProps, submit],
    );
    useFooterButtons(renderButtons ? buttons : []); // TODO: wrap one lvl up?

    return form;
};

export const withForm = Component => props => {
    const { stepperProps, stepFormProps } = props;
    const { Form, ...form } = useSteppedForm({
        stepperProps,
        stepFormProps,
        isTabVariant: false,
        renderButtons: true,
    });

    return (
        <Form disableBanner>
            <Component {...props} form={form} />
        </Form>
    );
};

const ControlledTabPaneFix = ({ title, tab, onClick }) => {
    return (
        <div
            style={{
                marginTop: "-12px",
                marginBottom: "-12px",
                marginLeft: "-8px",
                marginRight: "-8px",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "8px",
                paddingRight: "8px",
            }}
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onClick(tab);
            }}
        >
            {title}
        </div>
    );
};

const StepFormInner = ({
    stepperProps,
    isTabVariant,
    alert,
    title,
    onClickBack,
    alignButtons = "right",
    stretch,
    isRemote = false,
    allStepsProps,
    stepFormProps,
}) => {
    const {
        steps,
        goToStep,
        currentStepIndex, // TODO: currentStepKey
        currentStep: {
            Component,
            padding = [true, true],
            stepProps,
            key: currentStepKey,
        },
    } = stepperProps;
    const StepWithForm = useMemo(() => withForm(Component), [Component]);
    const headerSteps = useMemo(
        () => map(pick(["title", "key"]), steps),
        [steps],
    );
    const { Form, ...form } = useSteppedForm({
        stepperProps,
        stepFormProps,
        isTabVariant: true,
        renderButtons: isTabVariant,
    });
    const renderContent = () => {
        if (isTabVariant)
            return (
                <Form disableBanner>
                    {steps.map(({ Component, stepProps, key }) => {
                        return (
                            <div
                                key={key}
                                style={{
                                    display:
                                        key === currentStepKey
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <Component
                                    stepProps={stepProps}
                                    allStepsProps={allStepsProps}
                                    stepperProps={stepperProps}
                                    stepFormProps={stepFormProps}
                                    form={form}
                                />
                            </div>
                        );
                    })}
                </Form>
            );

        return (
            <StepWithForm
                stepProps={stepProps}
                allStepsProps={allStepsProps}
                stepperProps={stepperProps}
                stepFormProps={stepFormProps}
            />
        );
    };
    const { buttons } = useContext(FooterButtonsContext);
    const WrappedLayout = isRemote ? RemoteLayout : React.Fragment;
    const confirmModal = useConfirmModal();
    const onChangeTab = useCallback(
        async key => {
            const { getBag, submit } = form;
            const {
                dirty,
                values,
                validation: { isValid, isValidStrict },
            } = await getBag();

            if (!dirty) {
                goToStep({ key });
            } else {
                const confirmed = await confirmModal.confirm({
                    title: "Unsaved changes",
                    message: "Do you want to save unsaved changes?",
                    okText: "Save", // TODO
                    cancelText: t("general.cancel"),
                    // onConfirm: () => { console.log("OK") },
                });
                if (confirmed) {
                    // debugger;
                    await submit(() =>
                        stepFormProps.onTabChangeSave({ values }),
                    );
                    if (isValid) {
                        goToStep({ key });
                    }
                }
            }
        },
        [confirmModal, form, goToStep, stepFormProps],
    );

    const defaultTabsProps = {
        activeKey: currentStepKey,
        onChange: tab => {
            // never called, cannot prevent tab transition
            throw new Error("Should be never called");
        },
        fullSize: true,
        splitLayout: true,
    };

    console.log("[StepFormInner.rndr]", { defaultTabsProps });

    return (
        <WrappedLayout>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={title}
                    alert={alert && <Alert {...alert} />}
                    onClickBack={onClickBack}
                    steps={
                        !isTabVariant && (
                            <UnitySteps
                                current={currentStepIndex}
                                steps={headerSteps}
                            />
                        )
                    }
                    tabs={
                        isTabVariant && (
                            <Tabs {...defaultTabsProps}>
                                {headerSteps.map(({ title, key }) => (
                                    <Tabs.TabPane
                                        key={key}
                                        tab={
                                            <ControlledTabPaneFix
                                                title={title}
                                                tab={key}
                                                onClick={onChangeTab}
                                            />
                                        }
                                    />
                                ))}
                            </Tabs>
                        )
                    }
                />

                <UnityLayout.Content padding={padding} stretch={stretch}>
                    {renderContent()}
                </UnityLayout.Content>
                {buttons?.length && (
                    <UnityLayout.Footer
                        {...(alignButtons === "left"
                            ? {
                                  actionButtonsLeft: (
                                      <ButtonMenu buttons={buttons} />
                                  ),
                              }
                            : {
                                  actionButtons: (
                                      <ButtonMenu buttons={buttons} />
                                  ),
                              })}
                    />
                )}
            </UnityLayout>
        </WrappedLayout>
    );
};

const GET_STEP_BUTTONS = ({ stepperProps, formId }) => [];

export const StepForm = ({
    title,
    reactiveInitialValues,
    initialValues = reactiveInitialValues,
    onSubmit = noop,
    onTabChangeSave = onSubmit,
    onCancel,
    allStepsProps,
    steps,
    getStepButtons = GET_STEP_BUTTONS,
    allValues: allValuesProp,
    setAllValues: setAllValuesProp,
    isTabVariant,
}) => {
    const [_allValues, _setAllValues] = useState(initialValues || {});
    const [allValues, setAllValues] = setAllValuesProp
        ? [allValuesProp, setAllValuesProp]
        : [_allValues, _setAllValues];
    const stepperProps = useStepperProps({ steps });
    const stepFormProps = useMemo(
        () => ({
            initialValues,
            reactiveInitialValues,
            allValues,
            setAllValues,
            onSubmit,
            onTabChangeSave,
            onCancel,
            getStepButtons,
        }),
        [
            initialValues,
            reactiveInitialValues,
            allValues,
            setAllValues,
            onSubmit,
            onTabChangeSave,
            onCancel,
            getStepButtons,
        ],
    );

    return (
        <FooterButtonsProvider>
            <StepFormInner
                title={title}
                stepperProps={stepperProps}
                isTabVariant={isTabVariant}
                allStepsProps={allStepsProps}
                stepFormProps={stepFormProps}
                onClickBack={onCancel}
                alignButtons="left"
                stretch={false}
                isRemote
            />
        </FooterButtonsProvider>
    );
};
