import {
    useDeleteSftpUserMutation,
    useSftpServerUsersQuery,
} from "@/components/DataUploads/SftpManagement/loadables";
import SFTPServerInfo from "@/components/DataUploads/SftpManagement/SFTPServerInfo.component";
import { Button, Gap, H5, Text, UnityLayout } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { SFTPServerUsersList } from "@/components/SFTPServers/SFTPServerUsers.component";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import React, { useCallback } from "react";

export const IsvSftpConfiguration = ({ isvConnectionId }) => {
    const { locationRouterService, accountAppLocations } = useDic();

    const sftpServerUsersQuery = useSftpServerUsersQuery({
        dataLoadId: isvConnectionId,
        type: sftpEntityType.ISV_ENABLE,
    });
    const deleteSftpUserMutation = useDeleteSftpUserMutation({
        dataLoadId: isvConnectionId,
        type: sftpEntityType.ISV_ENABLE,
        afterSuccess: sftpServerUsersQuery.reload,
    });

    const createOrEdit = useCallback(
        userId => () =>
            locationRouterService.navigate(
                accountAppLocations.isvConnectionsSettingsEditSFTPUserLocation,
                {
                    userId,
                    SFTP_ENTITY_TYPE: sftpEntityType.ISV_ENABLE,
                    uploadId: isvConnectionId,
                },
            ),
        [
            locationRouterService,
            accountAppLocations.isvConnectionsSettingsEditSFTPUserLocation,
            isvConnectionId,
        ],
    );
    return (
        <UnityLayout>
            <UnityLayout.Content padding={[true, true]}>
                <H5>{t("isv-connections.steps.pricefx.title")}</H5>
                <Text size="small">
                    {t("isv-connections.steps.pricefx.perex")}
                </Text>
                <Gap />
                <SFTPServerInfo
                    dataLoadId={isvConnectionId}
                    type={sftpEntityType.ISV_ENABLE}
                />
                <SFTPServerUsersList
                    createOrEdit={createOrEdit}
                    sftpServerUsersQuery={sftpServerUsersQuery}
                    deleteSftpUserMutation={deleteSftpUserMutation}
                    fixed={true}
                    fixedHeight={200}
                    padding={false}
                    pagination={false}
                />
                <Button
                    onClick={createOrEdit("new")}
                    icon={Plus}
                    data-test="add-sftp-user"
                    label={t("general.add-user")}
                />
            </UnityLayout.Content>
        </UnityLayout>
    );
};
