import { idleLoadableProps } from "@/components/DataUploads/Wizard/loadables";
import { Forms, H2, H4, P } from "@/components/DesignSystem";
import { SELECT_GET_VALUE } from "@/components/DesignSystem/Forms/fields";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { RowSkeleton } from "@/components/DesignSystem/Skeleton/RowSkeleton.component";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "@/components/EventSchedulers/EventSchedulers.page";
import { ListenerActionStep } from "@/components/EventWorkflows/ListenerStepForm/ListenerAction.step";
import { PayloadFields } from "@/components/EventWorkflows/ListenerStepForm/PayloadFields";
import {
    SourceEventModal,
    SourceFields,
    getOptionLabelKey,
    getOptionLabelMaybe,
    useLoadableOptionsValidatorMaybe,
} from "@/components/EventWorkflows/ListenerStepForm/SourceEventModal";
import {
    useEOTActionEntitiesOptionsQuery,
    useEOTActionTypesOptionsQuery,
    useEOTEntitiesOptionsQuery,
    useEOTypesOptionsQuery,
} from "@/components/EventWorkflows/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { isLoading } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { constant, identity, noop } from "lodash/fp";
import React, { useCallback, useMemo } from "react";

const GeneralStep = ({ allStepsProps: { existingNames } }) => {
    return (
        <>
            <H2>{t("event-wf.listener-form.steps.general.title")}</H2>
            <P>{t("event-wf.listener-form.steps.general.perex")}</P>
            <Forms.FieldGroup>
                <Forms.Fields.Input
                    name="name"
                    label={t("event-wf.listener-form.name.label")}
                    required
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.createForbidValuesValidation(
                            existingNames,
                            "Already exists",
                        ),
                    ])}
                />
                <Forms.Fields.TextArea
                    name="description"
                    label={t("event-wf.listener-form.description.label")}
                    rows="3"
                />
                <Forms.Fields.InputNumber
                    name="timeoutInMinutes"
                    label={t("event-wf.listener-form.timeoutInMinutes.label")}
                    validator={Forms.validators.failOnFirst([
                        orEmpty(Forms.pmValidators.min(1)),
                    ])}
                    addonAfter={"min"}
                    tooltip={t(
                        "event-wf.listener-form.timeoutInMinutes.tooltip",
                    )}
                    inputWidth="default"
                    width="default"
                />
            </Forms.FieldGroup>
        </>
    );
};

export const STEP_SOURCE_EVENT_LOGIC = {
    ALL: "ALL",
    ONE_OF: "ONE_OF",
};

const COMBINATION_OPTIONS = [
    {
        label: t("event-wf.listener-form.combinationLogic.all.label"),
        value: STEP_SOURCE_EVENT_LOGIC.ALL,
    },
    {
        label: t("event-wf.listener-form.combinationLogic.oneOf.label"),
        value: STEP_SOURCE_EVENT_LOGIC.ONE_OF,
    },
];

// TODO: cached query?
const LoadableSourceName = ({ sourceId, sourceType, accountId }) => {
    const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: sourceType,
        canFetch: !!sourceType && !!sourceId,
    });
    const labelMaybe = getOptionLabelMaybe(
        sourceId,
        eotSourceEntitiesQuery.loadable,
    );

    if (labelMaybe) return <SourceName name={labelMaybe} sourceId={sourceId} />;
    else if (isLoading(eotSourceEntitiesQuery))
        return <SourceName name={<RowSkeleton />} sourceId={sourceId} />;
    else return <SourceName name={sourceId} sourceId={sourceId} />;
};

export const SourceName = ({ name, sourceId, sourceType, accountId }) => {
    if (name) return <span title={sourceId}>{name}</span>;
    else
        return (
            <LoadableSourceName
                sourceId={sourceId}
                sourceType={sourceType}
                accountId={accountId}
            />
        );
};

const SourceStep = ({
    allStepsProps: {
        accountId,
        isFirstListener,
        isSingleSource,
        singleSource,
        eoTypesQuery,
    },
}) => {
    const formId = Forms.useFormId();
    const sourceEventsValue = Forms.useFieldValue({
        formId,
        name: "sourceEvents",
    });
    const combinationLogicValue = Forms.useFieldValue({
        formId,
        name: "combinationLogic",
    });

    const eventsColumns = useMemo(
        () => [
            {
                name: "sourceType",
                label: t("event-wf.listener-form.sourceType.label"),
                type: fieldTypes.TEXT,
            },
            {
                name: getOptionLabelKey("sourceId"),
                label: t("event-wf.listener-form.sourceId.label"),
                type: fieldTypes.TEXT,
                render: (text, record) => (
                    <SourceName
                        name={record.sourceName}
                        accountId={accountId}
                        sourceId={record.sourceId}
                        sourceType={record.sourceType}
                    />
                ),
            },
            {
                name: "sourceEventType",
                label: t("event-wf.listener-form.sourceEventType.label"),
                type: fieldTypes.TEXT,
            },
            {
                name: "jsonPath",
                label: t("event-wf.listener-form.filter.jsonPath.label"),
                type: fieldTypes.TEXT,
                canFilter: false,
                canSort: false,
                render: (text, record) =>
                    record.filters?.map(({ jsonPath }) => jsonPath)?.join(","),
            },
            {
                name: "value",
                label: t("event-wf.listener-form.filter.value.label"),
                type: fieldTypes.TEXT,
                canFilter: false,
                canSort: false,
                render: (text, record) =>
                    record.filters?.map(({ value }) => value)?.join(","),
            },
            {
                type: fieldTypes.TEXT,
                label: t("general.notes"),
                name: "notes",
                canFilter: false,
                width: 55,
            },
        ],
        [accountId],
    );

    return (
        <>
            <H2>{t("event-wf.listener-form.steps.source.title")}</H2>
            <P>{t("event-wf.listener-form.steps.source.perex")}</P>
            <Forms.Fields.EntityEditorTable
                name="sourceEvents"
                title={t("event-wf.listener-form.sourceEvents.label")}
                addButtonProps={{
                    label: t("event-wf.listener-form.sourceEvents.add-button"),
                }}
                columns={eventsColumns}
                ModalComponent={SourceEventModal}
                getModalExtraProps={() => ({
                    accountId,
                    eoTypesQuery,
                    isSingleSource,
                    singleSource,
                })}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.createMinLengthValidation(1, {
                        map: identity,
                    }),
                ])}
                addAlertFn={addLocalFilteredAlerts([
                    {
                        prop: "notes",
                        getVisible: ({ invalid }) => invalid,
                        getType: constant("RED"),
                        getTooltip: constant(t("general.invalid")),
                    },
                ])}
                // rowHeight={36}
            />

            {sourceEventsValue?.length >= 2 && (
                <Forms.Fields.Radio
                    required
                    name="combinationLogic"
                    label={t("event-wf.listener-form.combinationLogic.label")}
                    options={COMBINATION_OPTIONS}
                    initialValue={STEP_SOURCE_EVENT_LOGIC.ALL}
                    validator={Forms.pmValidators.isRequired}
                    tooltip={t(
                        "event-wf.listener-form.combinationLogic.tooltip",
                    )}
                />
            )}
            {combinationLogicValue === STEP_SOURCE_EVENT_LOGIC.ALL && (
                <Forms.Fields.InputNumber
                    name="_stepTriggerTimeoutInMinutes"
                    label={t("event-wf.listener-form.timeout.label")}
                    validator={orEmpty(Forms.pmValidators.min(1))}
                    addonAfter={"min"}
                    tooltip={t("event-wf.listener-form.timeout.tooltip")}
                />
            )}
            <Forms.Fields.InputNumber
                name="delayedTriggerInMinutes"
                label={t("event-wf.listener-form.timein.label")}
                validator={orEmpty(Forms.pmValidators.min(1))}
                addonAfter={"min"}
                tooltip={t("event-wf.listener-form.timein.tooltip")}
            />
            {!isFirstListener && (
                <Forms.Fields.Checkbox
                    name="validatePreviousStepAsyncTaskId"
                    label={t(
                        "event-wf.listener-form.validatePreviousStepAsyncTaskId.label",
                    )}
                />
            )}
        </>
    );
};

export const TargetFields = ({
    accountId,
    isSingleSource,
    singleSource = {},
}) => {
    const eoTypesQuery = useEOTypesOptionsQuery({ accountId });
    const destinationType = TargetFields.useDestinationType();
    const eotTargetEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: destinationType,
        canFetch: !!destinationType,
    });
    const typeValidator = useLoadableOptionsValidatorMaybe({
        isRequired: true,
        loadable: eoTypesQuery.loadable,
    });
    const idValidator = useLoadableOptionsValidatorMaybe({
        isRequired: true,
        loadable: eotTargetEntitiesQuery.loadable,
    });

    if (isSingleSource) {
        return (
            <>
                <Forms.Fields.ReadOnlyValue
                    initialValue={
                        singleSource[SourceFields.fieldNames.sourceType]
                    }
                    name={TargetFields.fieldNames.destinationType}
                    label={t("event-wf.listener-form.destinationType.label")}
                    {...getLoadableSelectProps(eoTypesQuery.loadable)}
                    getValue={SELECT_GET_VALUE}
                />
                <Forms.Fields.ReadOnlyValue
                    initialValue={
                        singleSource[SourceFields.fieldNames.sourceId]
                    }
                    name={TargetFields.fieldNames.destinationId}
                    label={t("event-wf.listener-form.destinationId.label")}
                    {...getLoadableSelectProps(eotTargetEntitiesQuery.loadable)}
                    getValue={SELECT_GET_VALUE}
                />
            </>
        );
    }

    return (
        <>
            <Forms.Fields.Select
                initialValue={singleSource[SourceFields.fieldNames.sourceType]}
                name={TargetFields.fieldNames.destinationType}
                label={t("event-wf.listener-form.destinationType.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    typeValidator,
                ])}
                {...getLoadableSelectProps(eoTypesQuery.loadable)}
            />
            <Forms.Fields.Select
                initialValue={singleSource[SourceFields.fieldNames.sourceId]}
                name={TargetFields.fieldNames.destinationId}
                label={t("event-wf.listener-form.destinationId.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    idValidator,
                ])}
                {...getLoadableSelectProps(eotTargetEntitiesQuery.loadable)}
                {...idleLoadableProps(
                    eotTargetEntitiesQuery.loadable,
                    "Please, select type",
                )}
            />
        </>
    );
};
TargetFields.fieldNames = {
    destinationType: "destinationType",
    destinationId: "destinationId",
};
TargetFields.useDestinationType = ({ formId } = {}) => {
    return Forms.useFieldValue({
        formId: Forms.useFormId(formId),
        name: TargetFields.fieldNames.destinationType,
    });
};
TargetFields.useDestinationId = ({ formId } = {}) => {
    return Forms.useFieldValue({
        formId: Forms.useFormId(formId),
        name: TargetFields.fieldNames.destinationId,
    });
};

export const ActionFieldsVariant = {
    schedulers: "schedulers",
    workflows: "workflows",
};

export const ActionFields = ({
    accountId,
    setValues = noop,
    variant = ActionFieldsVariant.schedulers,
}) => {
    const { wfId } = useAccountAppParams();

    const formId = Forms.useFormId();
    const fieldNames =
        ActionFields.fieldNames?.[variant] ?? ActionFieldsVariant.schedulers;

    const destinationType = Forms.useFieldValue({
        formId,
        name: TargetFields.fieldNames.destinationType,
    });
    const eotTargetActionTypesQuery = useEOTActionTypesOptionsQuery({
        accountId,
        eoType: destinationType,
        canFetch: !!destinationType,
    });

    const destinationTargetType = Forms.useFieldValue({
        formId,
        name: fieldNames.destinationTargetType,
    });
    const destinationId = Forms.useFieldValue({
        formId,
        name: TargetFields.fieldNames.destinationId,
    });
    const missingActionsDeps = [
        [destinationType, "event-wf.listener-form.destinationType.label"],
        [
            destinationTargetType,
            "event-wf.listener-form.destinationTargetType.label",
        ],
        [destinationId, "event-wf.listener-form.destinationId.label"],
    ]
        .filter(([value, tsKey]) => !value)
        .map(([value, tsKey]) => t(tsKey));
    const eotTargetActionEntitiesQuery = useEOTActionEntitiesOptionsQuery({
        accountId,
        eoType: destinationType,
        entityId: destinationId,
        wfId,
        actionType: destinationTargetType,
        canFetch: !missingActionsDeps.length,
    });
    const handleTypeChange = useCallback(() => {
        setValues({ [fieldNames.destinationTargetId]: null });
    }, [setValues]);

    return (
        <>
            <Forms.Fields.Select
                name={fieldNames.destinationTargetType}
                label={t("event-wf.listener-form.destinationTargetType.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    useLoadableOptionsValidatorMaybe({
                        isRequired: true,
                        loadable: eotTargetActionTypesQuery.loadable,
                    }),
                ])}
                onChange={handleTypeChange}
                {...getLoadableSelectProps(eotTargetActionTypesQuery.loadable)}
                {...idleLoadableProps(
                    eotTargetActionTypesQuery.loadable,
                    "Please, select type",
                )}
            />
            <Forms.Fields.Select
                name={fieldNames.destinationTargetId}
                label={t("event-wf.listener-form.destinationTargetId.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    useLoadableOptionsValidatorMaybe({
                        isRequired: true,
                        loadable: eotTargetActionEntitiesQuery.loadable,
                    }),
                ])}
                {...getLoadableSelectProps(
                    eotTargetActionEntitiesQuery.loadable,
                )}
                {...idleLoadableProps(
                    eotTargetActionEntitiesQuery.loadable,
                    `Please, select ${missingActionsDeps.join(", ")}`,
                )}
            />
        </>
    );
};

// @TODO Sync names on BE
ActionFields.fieldNames = {
    workflows: {
        destinationTargetType: "targetType",
        destinationTargetId: "targetId",
    },
    schedulers: {
        destinationTargetType: "destinationTargetType",
        destinationTargetId: "destinationTargetId",
    },
};

const ActionFieldsTODO = () => {
    return "TODO";
};

const ENABLED_OPTIONS = [
    {
        label: t("general.enabled"),
        value: true,
    },
    {
        label: t("general.disabled"),
        value: false,
    },
];

const FALLBACK_ACTION_OPTIONS = [
    {
        label: "Terminate the Action",
        value: "terminate",
    },
    {
        label: "Skip the Action",
        value: "skip",
    },
    {
        label: "Custom Action",
        value: "custom",
    },
];

// TODO: next sprint
const FallbackStep = ({ allStepsProps }) => {
    const fallback = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: "fallback",
    });
    const fallbackAction = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: "fallbackAction",
    });

    return (
        <>
            <Forms.Fields.Radio
                required
                name="fallback"
                label={t("event-wf.listener-form.fallback.label")}
                tooltip={t("event-wf.listener-form.fallback.tooltip")}
                options={ENABLED_OPTIONS}
                validator={Forms.pmValidators.isRequired}
                initialValue={false}
            />
            {fallback && (
                <>
                    <Forms.Fields.InputNumber
                        name="retries"
                        label={t("event-wf.listener-form.retries.label")}
                        validator={orEmpty(Forms.pmValidators.min(1))}
                        tooltip={t("event-wf.listener-form.retries.tooltip")}
                        inputWidth="default"
                        width="default"
                    />
                    <Forms.Fields.InputNumber
                        name="fallbackTimeout"
                        label={t(
                            "event-wf.listener-form.fallbackTimeout.label",
                        )}
                        validator={orEmpty(Forms.pmValidators.min(1))}
                        addonAfter={"min"}
                        tooltip={t(
                            "event-wf.listener-form.fallbackTimeout.tooltip",
                        )}
                        inputWidth="default"
                        width="default"
                    />
                    <Forms.Fields.Radio
                        required
                        name="fallbackAction"
                        label={t("event-wf.listener-form.fallbackAction.label")}
                        tooltip={t(
                            "event-wf.listener-form.fallbackAction.tooltip",
                        )}
                        options={FALLBACK_ACTION_OPTIONS}
                        validator={Forms.pmValidators.isRequired}
                    />
                    {fallbackAction === "custom" && (
                        <>
                            <H4>{t("event-wf.form.custom-action")}</H4>
                            <ActionFieldsTODO />
                            <PayloadFields
                                heading={t(
                                    "event-wf.listener-form.fallback-payload",
                                )}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export const LISTENER_STEP_KEY = {
    GENERAL: "GENERAL",
    SOURCE: "SOURCE",
    ACTION: "ACTION",
    FALLBACK: "FALLBACK",
};

export const listenerStepFormSteps = [
    {
        key: LISTENER_STEP_KEY.GENERAL,
        title: t("event-wf.listener-form.steps.general.title"),
        Component: GeneralStep,
    },
    {
        key: LISTENER_STEP_KEY.SOURCE,
        title: t("event-wf.listener-form.steps.source.title"),
        Component: SourceStep,
    },
    {
        key: LISTENER_STEP_KEY.ACTION,
        title: t("event-wf.listener-form.steps.action.title"),
        Component: ListenerActionStep,
    },
    // TODO: next sprint
    // {
    //     key: LISTENER_STEP_KEY.FALLBACK,
    //     title: t("event-wf.listener-form.steps.fallback.title"),
    //     Component: FallbackStep,
    // },
];
