import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { SwitchAsync } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "@/components/EventSchedulers/EventSchedulers.page";
import { EventWorkflowCopyModal } from "@/components/EventWorkflows/EventWorkflowCopyModal";
import {
    useChangeEventWFStatusMutation,
    useDeleteEventWFMutation,
    useEventWorkflowsQuery,
} from "@/components/EventWorkflows/loadables";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getEventWFTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { constant } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";

export const createColumns = ({ accountId } = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.target"),
        name: "target",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.description"),
        name: "description",
    },
    {
        type: fieldTypes.OPTION,
        options: [
            { label: "Active", value: true },
            { label: "Inactive", value: false },
        ],
        label: t("event-wf.workflows.column.status"),
        name: "enabled",
        width: 64, // Not working?!?
        render: (enabled, record) => {
            return (
                <SwitchAsync
                    initialValue={enabled}
                    useMutation={useChangeEventWFStatusMutation}
                    from={({ checked }) => ({
                        wfId: record.id,
                        enabled: checked,
                        accountId,
                    })}
                    enabledResponseProp="enabled"
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                />
            );
        },
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-wf.workflows.column.lastRun"),
        name: "lastRun",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-wf.workflows.column.createdAt"),
        name: "createdAt",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.createdBy"),
        name: "createdBy",
        visible: false,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-wf.workflows.column.updatedAt"),
        name: "updatedAt",
        visible: false,
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.updatedBy"),
        name: "updatedBy",
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.notes"),
        name: "notes",
        width: 88,
        canFilter: false,
    },
];

const preferencesType = preferencesTypes.EVENT_LISTENER_WF_LIST_TABLE;

export const EventWorkflows = ({
    accountId,
    visible,
    onEdit,
    reloadToken,
    reloadAll,
}) => {
    const listQuery = useEventWorkflowsQuery({
        accountId,
        reloadToken,
    });
    const deleteMutation = useDeleteEventWFMutation({
        accountId,
        afterSuccess: reloadAll,
    });

    const dataSource = useMemo(
        () =>
            addLocalFilteredAlerts([
                {
                    prop: "notes",
                    getVisible: ({ unableToRun }) => unableToRun,
                    getType: constant("RED"),
                    getTooltip: constant(t("general.unable-to-run")),
                },
            ])(listQuery.loadable.valueMaybe() || []),
        [listQuery],
    );
    const loading = isLoading(listQuery);

    const copyModal = useDetailDrawerState();
    const onCopy = useCallback(
        record => {
            copyModal.show(record);
        },
        [copyModal],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("general.make-copy"),
                        onClick: onCopy,
                        track: { name: getTrackName("Copy") },
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "event workflow",
                        }),
                        confirm: {
                            title: t("event-wf.workflows.modal.delete.title"),
                            message: `${t(
                                "event-wf.workflows.modal.delete.message",
                            )}`,
                            onConfirm: record =>
                                deleteMutation.mutate({ wfId: record.id }),
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [deleteMutation, onCopy, onEdit],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={createColumns({ accountId })}
                loading={loading}
                dataSource={dataSource}
                fixed
                restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                rowKey="id"
                datasetSlicing="local"
                preferencesType={preferencesType}
                visible={visible}
                // exportUrl={`/api/accounts/${accountId}/event-workflows/export`}
            />
            {copyModal.record && (
                <EventWorkflowCopyModal
                    accountId={accountId}
                    record={copyModal.record}
                    visible={!!copyModal.record}
                    onCancel={copyModal.hide}
                    reloadAll={reloadAll}
                />
            )}
        </>
    );
};

EventWorkflows.propTypes = {
    accountId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.string,
    reloadAll: PropTypes.func.isRequired,
};
