import { Link } from "@/components/DesignSystem";
import {
    NotificationType,
    TaskType,
} from "@/components/Header/components/Notifications/types";
import { useDataDownload } from "@/components/PartitionDataManagement/Downloads/AvailableFilesPage";
import { WorkflowType } from "@/constants/CommonConstats";
import { TASK_STATUS } from "@/modules/async-tasks";
import { LocationLink } from "@/modules/router";
import { t } from "@/translations";
import { noop } from "lodash";
import React from "react";
import uuid from "uuid/v4";
const { useDic } = require("@/components/Dic/useDic.hook");
const { useUserBasicInfo } = require("@/security/hooks/useUserBasicInfo.hook");

const replaceDoneByReady = status => status.replace(TASK_STATUS.DONE, "READY");

const messageByTaskType = task => {
    switch (task.type) {
        case TaskType.SOLUTION_DEPLOY:
        case TaskType.SOLUTION_DELETE:
        case TaskType.UPLOAD_FILE:
        case TaskType.DOWNLOAD_FILE_EXTERNAL_STORAGE:
        case TaskType.INTEGRATION_TEMPLATE_DEPLOY:
            return `${t(`notifications.message.${task.type}`)} ${task.status}`;
        case TaskType.DOWNLOAD_FILE:
            return `${t(
                `notifications.message.${task.type}`,
            )} ${replaceDoneByReady(task.status)}`;
        case TaskType.COPY_EVENT_WORKFLOW: {
            return `${t(`notifications.message.${task.type}`, {
                name: task.context?.workflowName || "",
                status: task.status,
            })}`;
        }
        default:
            return `${task.type} ${task.status}`;
    }
};

const createTargetLinkList = (items, location, paramKey) =>
    items.map((item, index) => (
        <>
            <LocationLink
                key={index}
                $location={location}
                $params={{
                    accountId: 103,
                    [paramKey]: item.targetId,
                }}
            >
                {item.targetName}
            </LocationLink>
            {index < items.length - 1 && ", "}
        </>
    ));

export const createTitleByNotificationTypeFn =
    ({ eventWorkflowEditLocation, eventSchedulerEditLocation }) =>
    notification => {
        switch (notification.type) {
            case NotificationType.TASK:
                return messageByTaskType(notification.data);
            case NotificationType.PACKAGE_ROLLBACK:
            case NotificationType.PACKAGE_TERMINATION:
                const packageInfo = JSON.parse(notification.data);
                return t(`notifications.message.${notification.type}`, {
                    templateName: packageInfo.templateName,
                    partitionName: packageInfo.partition,
                });
            case NotificationType.WORKFLOW_APPROVAL:
                return t(`notifications.message.workflow.approval`, {
                    type: WorkflowType.translate(
                        notification.data?.workflowRequestData?.wfType,
                    ),
                });
            case NotificationType.TRANSPORT_IM_ENVIRONMENT:
                return `${t(`notifications.message.${notification.type}`, {
                    instanceName: notification.data?.instanceName,
                    sourceBranch: notification.data?.sourceBranch,
                    targetBranch: notification.data?.targetBranch,
                })}`;
            case NotificationType.INSTANCE_DELETED:
                return `${t(`notifications.message.${notification.type}`, {
                    instanceName: notification.value,
                })}`;
            case NotificationType.WORKFLOW_DELETED:
            case NotificationType.DATA_DOWNLOAD_DELETED:
                const type =
                    notification.type === NotificationType.WORKFLOW_DELETED
                        ? "workflow"
                        : "download";
                const data = JSON.parse(notification.data);
                if (notification.severity === "FAILED") {
                    const targetWorkflowsWithLinks = createTargetLinkList(
                        data.workflows,
                        eventWorkflowEditLocation,
                        "wfId",
                    );
                    const targetSchedulersWithLinks = createTargetLinkList(
                        data.schedulers,
                        eventSchedulerEditLocation,
                        "schedulerId",
                    );

                    const allTargetsWithLinks = (
                        <>
                            {targetWorkflowsWithLinks}
                            {targetWorkflowsWithLinks.length > 0 &&
                                targetSchedulersWithLinks.length > 0 &&
                                ", "}
                            {targetSchedulersWithLinks}
                        </>
                    );
                    return (
                        <>
                            <div>
                                {t(
                                    "notifications.message.workflow_download.delete.failed",
                                    { type, name: notification.value },
                                )}
                            </div>
                            <div style={{ marginTop: "4px" }}>
                                <>{allTargetsWithLinks}</>
                            </div>
                        </>
                    );
                }
                return (
                    <div>
                        {t(
                            "notifications.message.workflow_download.delete.success",
                            { type, name: notification.value },
                        )}
                    </div>
                );
            default:
                return `${notification.type} received`;
        }
    };

const DownloadFileComponent = ({ task }) => {
    const { downloadFile } = useDataDownload(task.context.downloadId);
    return (
        <div style={{ marginTop: "4px" }}>
            <Link
                onClick={() =>
                    downloadFile({
                        fileName: task.context.fileName,
                        filePath: task.context.filePath,
                    })
                }
            >
                Download the file
            </Link>
        </div>
    );
};

// const defaultWrap = ({ task }) => <Link>{task.context.text}</Link>;
export const messageByNotificationType = notification => {
    if (notification.type === NotificationType.TASK) {
        const task = notification.data;
        if (
            task.type === TaskType.DOWNLOAD_FILE &&
            task.status === TASK_STATUS.DONE
        )
            return <DownloadFileComponent task={task} />;
        if (
            task.type === TaskType.COPY_EVENT_WORKFLOW &&
            task.status === TASK_STATUS.FAILED &&
            task.errorMessage
        )
            return task.errorMessage;
    }

    return undefined;
};

export const useNotificationActions = () => {
    const {
        locationRouterService,
        locations: { tasksLocation, myApprovalsLocation },
        accountAppLocations: {
            partitionUploadHistoryLocation,
            instancesLocationWithTransport,
            accountDeploymentLogLocation,
            eventWorkflowEditLocation,
            eventSchedulerEditLocation,
        },
        marketplaceAppLocations: { packageDetailLocation },
    } = useDic();

    const basicInfo = useUserBasicInfo();

    const onClickByNotificationType = notification => {
        switch (notification.type) {
            case NotificationType.TASK: {
                const context = notification.data.context;

                if (TaskType.DOWNLOAD_FILE === notification.data.type) {
                    return noop;
                } else if (TaskType.UPLOAD_FILE === notification.data.type) {
                    return () => {
                        locationRouterService.navigate(
                            partitionUploadHistoryLocation,
                            context,
                        );
                    };
                } else if (TaskType.PACKAGE_DEPLOY === notification.data.type) {
                    return () =>
                        locationRouterService.navigate(
                            accountDeploymentLogLocation,
                            context,
                        );
                } else {
                    return () => {
                        locationRouterService.navigate(tasksLocation, {
                            status: notification.data?.status,
                        });
                    };
                }
            }

            case NotificationType.TRANSPORT_IM_ENVIRONMENT:
                return () => {
                    const forceReloadParam = uuid();
                    const data = notification.data;

                    locationRouterService.navigate(
                        instancesLocationWithTransport,
                        {
                            accountId: data?.projectId,
                            checkTransportFor: data?.instanceId,
                            mergeRequestId: data?.mergeRequestId,
                            forceReloadParam,
                        },
                    );
                };

            case NotificationType.WORKFLOW_APPROVAL:
                return () => {
                    locationRouterService.navigate(myApprovalsLocation);
                };
            case NotificationType.PACKAGE_ROLLBACK:
            case NotificationType.PACKAGE_TERMINATION:
                return () => {
                    const data = JSON.parse(notification.data);
                    locationRouterService.navigate(packageDetailLocation, {
                        accountId: basicInfo.userInfo.user.defaultProjectId,
                        partitionName: data.partition,
                    });
                };
            case NotificationType.WORKFLOW_DELETED:
            case NotificationType.DATA_DOWNLOAD_DELETED:
                return noop;
            default:
                return undefined;
        }
    };

    return {
        onClickByNotificationType,
        titleByNotificationType: createTitleByNotificationTypeFn({
            eventWorkflowEditLocation,
            eventSchedulerEditLocation,
        }),
    };
};
