import { useDic } from "@/components/Dic/useDic.hook";
import { ISV_SETTINGS_TABS } from "@/components/ISVConnections/ISVConnectionsSettings.page";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { SFTPServerUserCreation } from "@/components/SFTPServers/SFTPServerUserCreation.component";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import React, { useCallback } from "react";

export const IsvSftpServerUserPage = () => {
    const { isvConnectionId, userId } = useAccountAppParams();

    const {
        locationRouterService,
        accountAppLocations: { isvConnectionsSettingsLocation },
    } = useDic();

    const navigateToUsersList = useCallback(() => {
        locationRouterService.navigate(isvConnectionsSettingsLocation, {
            tab: ISV_SETTINGS_TABS.PRICEFX,
        });
    }, [locationRouterService, isvConnectionsSettingsLocation]);

    useSetBackButtonAction(navigateToUsersList);
    return (
        <SFTPServerUserCreation
            dataLoadId={isvConnectionId}
            entityType={sftpEntityType.ISV_ENABLE}
            userId={userId}
            navigateToUsersList={navigateToUsersList}
        />
    );
};

IsvSftpServerUserPage.propTypes = {};
