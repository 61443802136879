import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { size } from "lodash";
import { useCallback, useMemo } from "react";

export const useIsvConnectionNameExistsValidator = ({
    accountId,
    skipValidationFor,
}) => {
    const { isvService } = useDic();
    return useCallback(
        connectionName =>
            skipValidationFor && skipValidationFor === connectionName
                ? Forms.success()
                : isvService
                      .connectionNameExists(accountId, connectionName)
                      .then(foundConnections =>
                          size(foundConnections) > 0
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [skipValidationFor, isvService, accountId],
    );
};

export const useIsvConnectionNameValidator = ({ initialValues, accountId }) => {
    const connectionExistsValidator = useIsvConnectionNameExistsValidator({
        accountId,
        skipValidationFor: initialValues?.name,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                debounceAsync(500, connectionExistsValidator),
            ]),
        [connectionExistsValidator],
    );
};
