import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Button, Forms, H4, Switch, Table } from "@/components/DesignSystem";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "@/components/EventSchedulers/EventSchedulers.page";
import { ListenerStepForm } from "@/components/EventWorkflows/ListenerStepForm/ListenerStepForm";
import { TargetFields } from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { SourceFields } from "@/components/EventWorkflows/ListenerStepForm/SourceEventModal";
import {
    useEOTEntitiesOptionsQuery,
    useEOTypesOptionsQuery,
} from "@/components/EventWorkflows/loadables";
import { useWfNameExistsValidation } from "@/components/EventWorkflows/validators";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { TableButton } from "@/components/TableButton";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { identity } from "lodash";
import {
    concat,
    constant,
    flatMap,
    get,
    isEqual,
    map,
    omit,
    pick,
    pipe,
    set,
    tap,
    uniqWith,
    update,
} from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import uuid from "uuid/v4";

const createListenersColumns = ({ onEdit, onChange, onDelete }) => [
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.name.label"),
        name: "name",
        render: (text, record, rowIndex) => (
            <Button
                size="small"
                type="text"
                label={text}
                disabled={!onEdit}
                onClick={e => {
                    e.stopPropagation();
                    onEdit?.(record, { isFirst: rowIndex === 0 });
                }}
            />
        ),
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t("event-wf.listener.column.enabled.label"),
        name: "enabled",
        render: (enabled, record, index) => {
            return (
                <Switch
                    size="small"
                    value={enabled}
                    onChange={(enabled, e) =>
                        onChange({ _id: record._id, enabled })
                    }
                />
            );
        },
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t(
            "event-wf.listener.column.validatePreviousStepAsyncTaskId.label",
        ),
        name: "validatePreviousStepAsyncTaskId",
        render: (validatePreviousStepAsyncTaskId, record, index) => {
            return (
                <Switch
                    size="small"
                    value={validatePreviousStepAsyncTaskId}
                    disabled={index === 0}
                    onChange={(validatePreviousStepAsyncTaskId, e) =>
                        onChange({
                            _id: record._id,
                            validatePreviousStepAsyncTaskId,
                        })
                    }
                />
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.description.label"),
        name: "description",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.lastRun.label"),
        name: "lastRun",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.createdAt.label"),
        name: "createdAt",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.action.label"),
        name: "_action",
        render: (_, record) => (
            <TableButton
                label={t("general.delete")}
                onClick={() => onDelete(record)}
            />
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.notes"),
        name: "notes",
        width: 55,
    },
];

const updateListeners = (listener, listeners) => {
    const index = listeners.findIndex(({ _id }) => _id === listener._id);
    if (index > -1) {
        const newListeners = [...listeners];
        newListeners[index] = { ...newListeners[index], ...listener };
        return newListeners;
    } else {
        return [...listeners, { _id: uuid(), ...listener }];
    }
};

export const WORKFLOW_TYPE = {
    SINGLE: "SINGLE",
    MULTI: "MULTI",
};
const WORKFLOW_TYPES_OPTIONS = [
    {
        label: "Single-source",
        value: WORKFLOW_TYPE.SINGLE,
    },
    {
        label: "Multi-source",
        value: WORKFLOW_TYPE.MULTI,
    },
];

const Nothing = () => null;
const FieldError = ({ name, value, setValues, validator }) => {
    useEffect(() => {
        setValues({ [name]: value });
    }, [name, setValues, value]);

    return (
        <div style={{ marginBottom: "-0.8rem" }}>
            <Forms.Field
                name={name}
                as={Nothing}
                label={null}
                from={identity}
                validator={validator}
            />
        </div>
    );
};

const getUniqueSources = pipe(
    flatMap(get(["stepTrigger", "sourceEvents"])),
    map(
        pick([
            SourceFields.fieldNames.sourceType,
            SourceFields.fieldNames.sourceId,
        ]),
    ),
    uniqWith(isEqual),
);

const targetFields = [
    TargetFields.fieldNames.destinationType,
    TargetFields.fieldNames.destinationId,
];
const getUniqueTargets = pipe(map(pick(targetFields)), uniqWith(isEqual));

const updateListenerSource = ({ name, value }) =>
    update(["stepTrigger", "sourceEvents"], map(set(name, value)));

const sourceToTargetName = {
    [SourceFields.fieldNames.sourceType]:
        TargetFields.fieldNames.destinationType,
    [SourceFields.fieldNames.sourceId]: TargetFields.fieldNames.destinationId,
};
const updateListenerTarget = ({ name, value }) =>
    set(sourceToTargetName[name], value);

const toTargetTuple = (target = {}) => [
    target[TargetFields.fieldNames.destinationType],
    target[TargetFields.fieldNames.destinationId],
];
const toSourceTuple = (source = {}) => [
    source[SourceFields.fieldNames.sourceType],
    source[SourceFields.fieldNames.sourceId],
];

const log = arg => tap(console.log.bind(console, arg));

export const EventWFForm = ({
    accountId,
    title,
    initialValues: _initialValues = {
        workflow: { enabled: true, singleSource: {} },
        steps: [],
    },
    onSubmit,
    onCancel,
}) => {
    const initialValues = useMemoByDeepEquality(_initialValues);
    const isNew = !initialValues.workflow?.id;
    const listenerView = useDetailDrawerState();
    const setRecord = useCallback(
        initialValues => listenerView.show(initialValues, identity),
        [listenerView.show],
    );

    const [listeners, setListeners] = useState(
        initialValues.steps.map(step => ({ _id: uuid(), ...step })),
    );
    const { formId, handleSubmit, setValues, getBag } = Forms.useForm({
        initialValues: initialValues.workflow,
        onSubmit: ({
            values: {
                _listeners,
                workflowType,
                sourceType,
                sourceId,
                ...values
            },
        }) =>
            onSubmit({
                workflow: {
                    ...values,
                    workflowType,
                    ...(workflowType === WORKFLOW_TYPE.SINGLE
                        ? {
                              singleSource: {
                                  sourceType,
                                  sourceId,
                              },
                          }
                        : {}),
                },
                steps: listeners
                    .map(omit(["_id"]))
                    .map((l, i) => ({ ...l, stepOrder: i })),
            }),
    });
    const workflowTypeValue = Forms.useFieldValue({
        formId,
        name: "workflowType",
    });
    const initialSource = initialValues.workflow?.singleSource; // || uniqueSources?.[0]; new -> multi -> add listener -> single?
    const initialWorkflowType = initialValues.workflow?.singleSource
        ? WORKFLOW_TYPE.SINGLE
        : WORKFLOW_TYPE.MULTI;

    const isSingleSource = workflowTypeValue === WORKFLOW_TYPE.SINGLE;
    const singleSource = {
        sourceType: SourceFields.useSourceType({ formId }),
        sourceId: SourceFields.useSourceId({ formId }),
    };

    const eoTypesQuery = useEOTypesOptionsQuery({ accountId });
    const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: singleSource.sourceType,
        canFetch: !!singleSource.sourceType,
    });
    const onSingleSourceChange = useCallback(({ meta, name, value }) => {
        setListeners(
            map(
                pipe(
                    updateListenerSource({ name, value }),
                    updateListenerTarget({ name, value }),
                ),
            ),
        );
    }, []);

    const listenerFormDisabled =
        isSingleSource && (!singleSource.sourceType || !singleSource.sourceId);
    const typeChangeDisabled = useMemo(() => {
        const uniqueSources = getUniqueSources(listeners);
        const uniqueTargets = getUniqueTargets(listeners);
        const uniqueTuples = pipe(
            concat(uniqueSources.map(toSourceTuple)),
            concat(uniqueTargets.map(toTargetTuple)),
            uniqWith(isEqual),
        )([]);
        return uniqueTuples.length > 1;
    }, [listeners]);

    const onAdd = useCallback(() => {
        listenerView.show({}, { isFirst: listeners.length === 0 });
    }, [listenerView, listeners]);
    const onDelete = useCallback(
        record =>
            setListeners(listeners =>
                listeners.filter(listener => listener._id !== record._id),
            ),
        [],
    );
    const onChange = useCallback(partialListener => {
        setListeners(listeners => updateListeners(partialListener, listeners));
    }, []);
    const listenersColumns = useMemo(
        () =>
            createListenersColumns({
                onEdit: !listenerFormDisabled && listenerView.show,
                onChange,
                onDelete,
            }),
        [listenerView.show, listenerFormDisabled, onChange, onDelete],
    );
    const onListenerSubmit = useCallback(values => {
        setListeners(listeners =>
            updateListeners({ enabled: true, ...values }, listeners),
        );
        // listenerView.hide();
    }, []);
    const onDrop = useCallback(({ oldIndex: from, newIndex: to }) => {
        setListeners(listeners => {
            const newListeners = [...listeners];
            const [moved] = newListeners.splice(from, 1);
            newListeners.splice(to, 0, moved);
            const [firstListener, ...restListeners] = newListeners;
            //Every time the listener is moved to the first position, the validatePreviousStepAsyncTaskId should be false
            return [
                {
                    ...firstListener,
                    validatePreviousStepAsyncTaskId: false,
                },
                ...restListeners,
            ];
        });
    }, []);
    const skipValidation = useCallback(
        ({ name, sourceType, sourceId }) => {
            const incompleteParams =
                isSingleSource && (!sourceType || !sourceId);
            const sourceMatch =
                initialWorkflowType === workflowTypeValue &&
                sourceType === initialSource?.sourceType &&
                sourceId === initialSource?.sourceId;
            const isExistingEdit =
                !isNew && name === initialValues.workflow?.name && sourceMatch;

            return incompleteParams || isExistingEdit;
        },
        [
            initialSource?.sourceId,
            initialSource?.sourceType,
            initialValues.workflow?.name,
            initialWorkflowType,
            isNew,
            isSingleSource,
            workflowTypeValue,
        ],
    );
    const wfNameExistsValidation = useWfNameExistsValidation({
        accountId,
        skipValidation,
        isSingleSource: workflowTypeValue === WORKFLOW_TYPE.SINGLE,
        sourceType: singleSource.sourceType,
        sourceId: singleSource.sourceId,
    });

    return (
        <>
            <div hidden={listenerView.record}>
                <Forms.Form formId={formId} onSubmit={handleSubmit}>
                    <Forms.FieldGroup>
                        <H4>{t("event-wf.form.general")}</H4>
                        <Forms.Fields.Switch
                            name="enabled"
                            label={t("event-wf.form.enabled.label")}
                            layout="horizontal"
                            noMaxWidth
                            textOn={t("general.enabled")}
                            textOff={t("general.disabled")}
                        />
                        <Forms.Fields.Input
                            name="name"
                            label={t("event-wf.form.name.label")}
                            validator={Forms.validators.failOnFirst([
                                Forms.pmValidators.isRequired,
                                Forms.pmValidators.createMinLengthValidation(1),
                                Forms.pmValidators.createMaxLengthValidation(
                                    255,
                                ),
                                wfNameExistsValidation,
                            ])}
                            required
                        />
                        <Forms.Fields.Input
                            name="description"
                            label={t(
                                "event-wf.listener-form.description.label",
                            )}
                            validator={Forms.validators.failOnFirst([
                                orEmpty(
                                    Forms.pmValidators.createMaxLengthValidation(
                                        255,
                                    ),
                                ),
                            ])}
                        />
                        <Forms.Fields.InputNumber
                            name="executionOrder"
                            label={t("event-wf.form.executionOrder.label")}
                            validator={orEmpty(Forms.pmValidators.min(1))}
                        />
                        <Forms.Fields.InputNumber
                            name="timeoutInMinutes"
                            label={t("event-wf.form.timeoutInMinutes.label")}
                            placeholder={t(
                                "event-wf.form.timeoutInMinutes.placeholder",
                            )}
                            validator={Forms.validators.failOnFirst([
                                // Forms.pmValidators.isRequired,
                                orEmpty(Forms.pmValidators.min(1)),
                            ])}
                            // required
                            addonAfter={"min"}
                            tooltip={t(
                                "event-wf.form.timeoutInMinutes.tooltip",
                            )}
                        />
                        <H4>{t("event-wf.form.types")}</H4>
                        <Forms.Fields.Radio
                            required
                            name="workflowType"
                            label={t("event-wf.form.workflowType.label")}
                            options={WORKFLOW_TYPES_OPTIONS}
                            validator={Forms.pmValidators.isRequired}
                            tooltip={t("event-wf.form.workflowType.tooltip")}
                            disabled={typeChangeDisabled}
                            initialValue={initialWorkflowType}
                        />
                        {isSingleSource && (
                            <SourceFields
                                eoTypesQuery={eoTypesQuery}
                                eotSourceEntitiesQuery={eotSourceEntitiesQuery}
                                onChange={onSingleSourceChange}
                                initialValues={initialSource}
                            />
                        )}
                    </Forms.FieldGroup>
                    <Forms.FieldGroup width="max" inputWidth="max">
                        <H4>Listeners</H4>
                        <Table
                            rowKey="name"
                            columns={listenersColumns}
                            dataSource={addLocalFilteredAlerts([
                                {
                                    prop: "notes",
                                    getVisible: ({ unableToRun }) =>
                                        unableToRun,
                                    getType: constant("RED"),
                                    getTooltip: constant(
                                        t("general.unable-to-run"),
                                    ),
                                },
                            ])(listeners)}
                            pagination={false}
                            hasColumnAutofit
                            hasColumnResizing
                            rowDragDrop={{ onDrop }}
                        />
                        <FieldError
                            name="_listeners"
                            value={listeners}
                            setValues={setValues}
                            validator={Forms.validators.failOnFirst([
                                Forms.pmValidators.isRequired,
                                Forms.pmValidators.createMinLengthValidation(
                                    1,
                                    { map: identity },
                                ),
                            ])}
                        />
                        {/* <Gap size="small" /> */}
                        <Button
                            data-test="add-listener-button"
                            icon={Plus}
                            label={t("event-wf.form.add-listener")}
                            onClick={onAdd}
                            disabled={listenerFormDisabled}
                        />
                    </Forms.FieldGroup>
                    <Forms.SubmitButton formId={formId}>
                        <Button
                            data-test={isNew ? "create-button" : "save-button"}
                            htmlType="submit"
                            type="primary"
                            label={
                                isNew ? t("general.create") : t("general.save")
                            }
                        />
                    </Forms.SubmitButton>
                    <Button
                        data-test="cancel-button"
                        htmlType="button"
                        type="text"
                        onClick={onCancel}
                        label={t("general.cancel")}
                        disabled={false}
                    />
                </Forms.Form>
            </div>
            {!!listenerView.record && (
                <ListenerStepForm
                    accountId={accountId}
                    initialValues={listenerView.record}
                    setInitialValues={setRecord}
                    listeners={listeners}
                    isFirstListener={listenerView.value?.isFirst}
                    isSingleSource={isSingleSource}
                    singleSource={singleSource}
                    eoTypesQuery={eoTypesQuery}
                    onSubmit={onListenerSubmit}
                    onCancel={listenerView.hide}
                />
            )}
        </>
    );
};

EventWFForm.propTypes = {
    initialValues: PropTypes.object,
    title: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
