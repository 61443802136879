const COMPONENTS = {
    PartitionsList: "PartitionsList",
    CustomerPartitionsList: "CustomerPartitionsList",
    InstancesList: "InstancesList",
    IntegrationsRoutes: "IntegrationsRoutes",
    InstanceRepoIntegrationsRoutes: "InstanceRepoIntegrationsRoutes",
    EventWF: "EventWF",
    EventScheduler: "EventScheduler",
    CopyRouteDialog: "CopyRouteDialog",
    ConnectionsList: "ConnectionsList",
    InstanceRepoConnections: "InstanceRepoConnections",
    ClassesList: "ClassesList",
    ClassForm: "ClassForm",
    InstanceRepoClasses: "InstanceRepoClasses",
    CopyConnectionDialog: "CopyConnectionDialog",
    CopyClassDialog: "CopyClassDialog",
    ConnectionForm: "ConnectionForm",
    MappersList: "MappersList",
    InstanceRepoMappersList: "InstanceRepoMappersList",
    CopyMapperDialog: "CopyMapperDialog",
    MapperForm: "MapperForm",
    CreateLocalCopyModal: "CreateLocalCopyModal",
    FiltersList: "FiltersList",
    InstanceRepoFilters: "InstanceRepoFilters",
    RouteSendPayload: "RouteSendPayload",
    DataUploadsList: "DataUploadsList",
    DataDownloadsList: "DataDownloadsList",
    FailedEventsList: "FailedEventsList",
    RouteEditForm: "RouteEditForm",
    TransportReadyModal: "TransportReadyModal",
    IMUpdateLeaveModal: "IMUpdateLeaveModal",
    IMConvertImageModal: "IMConvertImageModal",
    IMDirectUpdateModal: "IMDirectUpdateModal",
    RestartingRoutesModal: "RestartingRoutesModal",
    CertificatesList: "CertificatesList",
    InstanceRepoCertificatesList: "InstanceRepoCertificatesList",
    CustomerPartitionCredentialsForm: "CustomerPartitionCredentialsForm",
    CalculationEnginesList: "CalculationEnginesList",
    CopyPartition: "CopyPartition",
    ISVConnections: "ISVConnections",
    NewIntegrationForm: "NewIntegrationForm",
    ClusterUpgradeRequestForm: "ClusterUpgradeRequestForm",
    StopIntegrations: "StopIntegrations",
};

export const getPartitionsListRemoteLoginTrackName = user =>
    `${COMPONENTS.PartitionsList} RemoteLogin ${user}`;

export const getPartitionsListTrackName = action =>
    `${COMPONENTS.PartitionsList} ${action}`;

export const getCustomerPartitionsListRemoteLoginTrackName = user =>
    `${COMPONENTS.CustomerPartitionsList} RemoteLogin ${user}`;

export const getCustomerPartitionsListTrackName = action =>
    `${COMPONENTS.CustomerPartitionsList} ${action}`;

export const getInstancesListTrackName = action =>
    `${COMPONENTS.InstancesList} ${action}`;

export const getCreateJobTaskAlertTrackName = (title, action) =>
    `${title} ${action}`;

export const getIntegrationRoutesTrackName = action =>
    `${COMPONENTS.IntegrationsRoutes} ${action}`;

export const getISVConnectionsTrackName = action =>
    `${COMPONENTS.ISVConnections} ${action}`;
export const getEventWFTrackName = action => `${COMPONENTS.EventWF} ${action}`;
export const getEventSchedulerTrackName = action =>
    `${COMPONENTS.EventScheduler} ${action}`;

export const getInstanceRepoIntegrationsRoutesTrackName = action =>
    `${COMPONENTS.InstanceRepoIntegrationsRoutes} ${action}`;

export const getCopyRouteDialogTrackName = action =>
    `${COMPONENTS.CopyRouteDialog} ${action}`;

export const getConnectionsListTrackName = action =>
    `${COMPONENTS.ConnectionsList} ${action}`;

export const getInstanceRepoConnectionsTrackName = action =>
    `${COMPONENTS.InstanceRepoConnections} ${action}`;

export const getClassesListTrackName = action =>
    `${COMPONENTS.ClassesList} ${action}`;

export const getInstanceRepoClassesTrackName = action =>
    `${COMPONENTS.InstanceRepoClasses} ${action}`;

export const getClassFormTrackName = action =>
    `${COMPONENTS.ClassForm} ${action}`;

export const getCopyClassDialogTrackName = action =>
    `${COMPONENTS.CopyClassDialog} ${action}`;

export const getCopyConnectionDialogTrackName = action =>
    `${COMPONENTS.CopyConnectionDialog} ${action}`;

export const getConnectionFormTrackName = action =>
    `${COMPONENTS.ConnectionForm} ${action}`;

export const getMappersListTrackName = action =>
    `${COMPONENTS.MappersList} ${action}`;

export const getInstanceRepoMappersListTrackName = action =>
    `${COMPONENTS.InstanceRepoMappersList} ${action}`;

export const getCopyMapperDialogTrackName = action =>
    `${COMPONENTS.CopyMapperDialog} ${action}`;

export const getMapperFormTrackName = action =>
    `${COMPONENTS.MapperForm} ${action}`;

export const getCreateLocalCopyModalTrackName = action =>
    `${COMPONENTS.CreateLocalCopyModal} ${action}`;

export const getFiltersListTrackName = action =>
    `${COMPONENTS.FiltersList} ${action}`;

export const getInstanceRepoFiltersTrackName = action =>
    `${COMPONENTS.InstanceRepoFilters} ${action}`;

export const getRouteSendPayloadTrackName = action =>
    `${COMPONENTS.RouteSendPayload} ${action}`;

export const getDataUploadsListTrackName = action =>
    `${COMPONENTS.DataUploadsList} ${action}`;
export const getDataDownloadsListTrackName = action =>
    `${COMPONENTS.DataDownloadsList} ${action}`;

export const getFailedEventsListTrackName = action =>
    `${COMPONENTS.FailedEventsList} ${action}`;

export const getRouteEditFormTrackName = action =>
    `${COMPONENTS.RouteEditForm} ${action}`;

export const getTransportReadyModalTrackName = action =>
    `${COMPONENTS.TransportReadyModal} ${action}`;

export const getCertificatesListTrackName = action =>
    `${COMPONENTS.CertificatesList} ${action}`;

export const getInstanceRepoCertificatesListTrackName = action =>
    `${COMPONENTS.InstanceRepoCertificatesList} ${action}`;

export const getCustomerPartitionCredentialsFormTrackName = action =>
    `${COMPONENTS.CustomerPartitionCredentialsForm} ${action}`;

export const getCalculationEnginesListTrackName = action =>
    `${COMPONENTS.CalculationEnginesList} ${action}`;

export const getIMUpdateLeaveModalTrackName = action =>
    `${COMPONENTS.IMUpdateLeaveModal} ${action}`;
export const getIMConvertImageModalTrackName = action =>
    `${COMPONENTS.IMConvertImageModal} ${action}`;
export const getDirectUpdateModalTrackName = action =>
    `${COMPONENTS.IMDirectUpdateModal} ${action}`;

export const getRestartingRoutesModalTrackName = action =>
    `${COMPONENTS.RestartingRoutesModal} ${action}`;

export const getCopyPartitionTrackName = action =>
    `${COMPONENTS.CopyPartition} ${action}`;

export const getNewIntegrationFormName = action =>
    `${COMPONENTS.NewIntegrationForm} ${action}`;

export const getClusterUpgradeRequestFormName = (type, action) =>
    `${type}${COMPONENTS.ClusterUpgradeRequestForm} ${action}`;
export const getStopIntegrationsTrackName = (type, action) =>
    `${type}${COMPONENTS.StopIntegrations} ${action}`;
