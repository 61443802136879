import FieldGridNotPadded from "@/components/DataUploads/SftpManagement/FieldGridNotPadded.component";
import {
    Button,
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import {
    useCopyWFMutation,
    useEOTActionEntitiesOptionsQuery,
    useEOTEntitiesOptionsQuery,
    useEventWFOriginsQuery,
} from "@/components/EventWorkflows/loadables";
import { useWfNameExistsValidation } from "@/components/EventWorkflows/validators";
import { Hidden } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { isLoading, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { ReactComponent as ArrowRight } from "@pricefx/unity-components/src/icons/unicons/arrow-right.svg";
import { eq, filter, map, noop, pipe, reduce, set, toPairs } from "lodash/fp";
import React, { useMemo, useRef } from "react";

const LAYOUT = [{}, {}, {}, { width: 250 }, { width: 32 }, { width: 250 }];

const createCopyOriginal =
    ({ setValues, getRowBag, getFieldName, rowId }) =>
    () =>
        getRowBag().then(({ value }) =>
            setValues({
                [getFieldName(rowId, "copy.id")]: value["original.id"],
            }),
        );

const AssetsMapping = ({
    name,
    originalLabel,
    copyLabel,
    assets,
    SelectComponent = Forms.Fields.Select,
    selectExtraProps = {},
    setValues = noop,
}) => {
    const initialValueRef = useRef(
        assets.map(({ type, id, configurationId }) => ({
            "original.type": type,
            "original.id": id,
            "original.label": configurationId,
            "copy.type": type,
            "copy.id": id,
        })),
    );

    return (
        <FieldGridNotPadded>
            <Forms.FieldGrid layout={LAYOUT}>
                <Forms.List name={name} initialValue={initialValueRef.current}>
                    {({ rows, getFieldName }) =>
                        rows.map(({ id, fieldProps, getBag }, index) => (
                            <Forms.FieldGrid.Row key={id}>
                                <Hidden {...fieldProps("original.type")} />
                                <Hidden {...fieldProps("copy.type")} />
                                <Hidden {...fieldProps("original.id")} />
                                <Forms.Fields.Input
                                    disabled
                                    label={originalLabel}
                                    {...fieldProps("original.label")}
                                />
                                <Forms.UIField name="actions">
                                    <Button
                                        icon={ArrowRight}
                                        onClick={createCopyOriginal({
                                            setValues,
                                            getFieldName,
                                            rowId: id,
                                            getRowBag: getBag,
                                        })}
                                    />
                                </Forms.UIField>

                                <SelectComponent
                                    {...fieldProps("copy.id")}
                                    required
                                    label={copyLabel}
                                    validator={Forms.pmValidators.isRequired}
                                    asset={assets[index]}
                                    {...selectExtraProps}
                                />
                            </Forms.FieldGrid.Row>
                        ))
                    }
                </Forms.List>
            </Forms.FieldGrid>
        </FieldGridNotPadded>
    );
};

const PMActionsMapping = ({
    name,
    originalLabel,
    copyLabel,
    pmActions,
    SelectComponent = Forms.Fields.Select,
    selectExtraProps = {},
    setValues,
}) => {
    const initialValue = useMemo(
        () =>
            pmActions.map(
                ({
                    type,
                    id,
                    configurationId,
                    targetType,
                    actionId,
                    actionLabel,
                }) => ({
                    "original.targetType": targetType,
                    "original.id": actionId,
                    "original.label": actionLabel,
                    "copy.targetType": targetType,
                    "copy.id": actionId,
                }),
            ),
        [pmActions],
    );

    return (
        <FieldGridNotPadded>
            <Forms.FieldGrid layout={LAYOUT}>
                <Forms.List name={name} initialValue={initialValue}>
                    {({ rows, getFieldName }) =>
                        rows.map(({ id, fieldProps, getBag }, index) => (
                            <Forms.FieldGrid.Row key={id}>
                                <Hidden
                                    {...fieldProps("original.targetType")}
                                />
                                <Hidden {...fieldProps("copy.targetType")} />
                                <Hidden {...fieldProps("original.id")} />
                                <Forms.Fields.Input
                                    disabled
                                    label={originalLabel}
                                    {...fieldProps("original.label")}
                                />
                                <Forms.UIField name="actions">
                                    <Button
                                        icon={ArrowRight}
                                        onClick={createCopyOriginal({
                                            setValues,
                                            getFieldName,
                                            rowId: id,
                                            getRowBag: getBag,
                                        })}
                                    />
                                </Forms.UIField>
                                <SelectComponent
                                    {...fieldProps("copy.id")}
                                    required
                                    label={copyLabel}
                                    validator={Forms.pmValidators.isRequired}
                                    pmAction={pmActions[index]}
                                    {...selectExtraProps}
                                />
                            </Forms.FieldGrid.Row>
                        ))
                    }
                </Forms.List>
            </Forms.FieldGrid>
        </FieldGridNotPadded>
    );
};

const GROUP_KEY = {
    TARGET: "TARGET",
    WORKFLOW: "WORKFLOW",
    DOWNLOAD: "DOWNLOAD",
    OTHER: "OTHER",
};

const getGroupKey = ({
    type,
    id,
    configurationId,
    targetType,
    actionId,
    actionLabel,
}) => {
    if (type !== "PFM") return GROUP_KEY.TARGET;
    if (targetType === "WORKFLOW") return GROUP_KEY.WORKFLOW;
    if (targetType === "DATA_DOWNLOAD") return GROUP_KEY.DOWNLOAD;
    return GROUP_KEY.OTHER;
};

const AssetSelect = ({ asset, accountId, ...props }) => {
    const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: asset.type,
    });
    return (
        <Forms.Fields.Select
            {...getLoadableSelectProps(eotSourceEntitiesQuery.loadable)}
            {...props}
        />
    );
};

const ActionSelect = ({ pmAction, accountId, ...props }) => {
    const eotTargetActionEntitiesQuery = useEOTActionEntitiesOptionsQuery({
        accountId,
        eoType: pmAction.type,
        entityId: pmAction.id,
        actionType: pmAction.targetType,
        canFetch: true,
    });
    return (
        <Forms.Fields.Select
            {...getLoadableSelectProps(eotTargetActionEntitiesQuery.loadable)}
            {...props}
        />
    );
};

const toNested = map(
    pipe(
        toPairs,
        reduce((acc, [k, v]) => set(k, v, acc), {}),
    ),
);

export const EventWorkflowCopyModal = ({
    accountId,
    record,
    visible,
    onCancel,
    reloadAll,
}) => {
    const query = useEventWFOriginsQuery({ accountId, id: record.id });
    const mutation = useCopyWFMutation({
        accountId,
        id: record.id,
        afterSuccess: () => {
            onCancel();
            reloadAll();
        },
    });
    const { formId, handleSubmit, setValues } = Forms.useForm({
        onSubmit: ({ values }) => {
            const {
                _sources,
                _targets,
                _workflows,
                _downloads,
                ...restValues
            } = values;
            const payload = {
                ...restValues,
                assetMappings: toNested([..._sources, ..._targets]),
                platformActionMappings: toNested([
                    ..._workflows,
                    ..._downloads,
                ]),
            };
            mutation.mutate(payload);
        },
    });
    const wfNameExistsValidation = useWfNameExistsValidation({
        accountId,
        isSingleSource: !!record.singleSource,
        sourceType: record.singleSource?.sourceType,
        sourceId: record.singleSource?.sourceId,
    });

    return (
        <Modal visible={!!record} onClose={onCancel} width={600}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("event-wf.copy.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Input
                                required
                                name="workflowName"
                                label={t(
                                    "event-wf.copy.form.workflowName.label",
                                )}
                                validator={Forms.validators.failOnFirst([
                                    Forms.pmValidators.isRequired,
                                    Forms.pmValidators.createMinLengthValidation(
                                        1,
                                    ),
                                    Forms.pmValidators.createMaxLengthValidation(
                                        255,
                                    ),
                                    wfNameExistsValidation,
                                ])}
                                initialValue={`${record.name} (copy)`}
                                // validateOnMount={false}
                            />
                            <Forms.Fields.Switch
                                name="enabled"
                                label={t("event-wf.copy.form.enabled.label")}
                                layout="horizontal"
                                noMaxWidth
                                textOn={t("general.enabled")}
                                textOff={t("general.disabled")}
                                initialValue
                            />
                            <Text>{t("event-wf.copy.form.perex")}</Text>
                            <Gap />
                            <LoadableRenderer
                                loadable={query.loadable}
                                hasValue={origins => (
                                    <>
                                        <AssetsMapping
                                            name="_sources"
                                            originalLabel={t(
                                                "event-wf.copy.form.source.original.label",
                                            )}
                                            copyLabel={t(
                                                "event-wf.copy.form.source.copy.label",
                                            )}
                                            assets={origins.sources}
                                            SelectComponent={AssetSelect}
                                            selectExtraProps={{ accountId }}
                                            setValues={setValues}
                                        />
                                        <AssetsMapping
                                            name="_targets"
                                            originalLabel={t(
                                                "event-wf.copy.form.target.original.label",
                                            )}
                                            copyLabel={t(
                                                "event-wf.copy.form.target.copy.label",
                                            )}
                                            assets={filter(
                                                pipe(
                                                    getGroupKey,
                                                    eq(GROUP_KEY.TARGET),
                                                ),
                                                origins.targets,
                                            )}
                                            SelectComponent={AssetSelect}
                                            selectExtraProps={{ accountId }}
                                            setValues={setValues}
                                        />
                                        <PMActionsMapping
                                            name="_workflows"
                                            originalLabel={t(
                                                "event-wf.copy.form.workflows.original.label",
                                            )}
                                            copyLabel={t(
                                                "event-wf.copy.form.workflows.copy.label",
                                            )}
                                            pmActions={filter(
                                                pipe(
                                                    getGroupKey,
                                                    eq(GROUP_KEY.WORKFLOW),
                                                ),
                                                origins.targets,
                                            )}
                                            SelectComponent={ActionSelect}
                                            selectExtraProps={{ accountId }}
                                            setValues={setValues}
                                        />
                                        <PMActionsMapping
                                            name="_downloads"
                                            originalLabel={t(
                                                "event-wf.copy.form.pmActions.original.label",
                                            )}
                                            copyLabel={t(
                                                "event-wf.copy.form.pmActions.copy.label",
                                            )}
                                            pmActions={filter(
                                                pipe(
                                                    getGroupKey,
                                                    eq(GROUP_KEY.DOWNLOAD),
                                                ),
                                                origins.targets,
                                            )}
                                            SelectComponent={ActionSelect}
                                            selectExtraProps={{ accountId }}
                                            setValues={setValues}
                                        />
                                    </>
                                )}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.create"),
                                    type: "primary",
                                    htmlType: "submit",
                                    formId,
                                    disabled:
                                        isLoading(query) || isLoading(mutation),
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
