import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    createTitleByNotificationTypeFn,
    messageByNotificationType,
    useNotificationActions,
} from "@/components/Header/components/Notifications/useNotificationActions.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { identity } from "lodash/fp";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.NOTIFICATIONS_LIST_TABLE;

const toOptions = (stringArrayMaybe = []) =>
    stringArrayMaybe.map(value => ({
        value,
        label: value,
    }));

const NotificationsList = () => {
    const {
        axiosService,
        notificationsService,
        accountAppLocations: {
            eventWorkflowEditLocation,
            eventSchedulerEditLocation,
        },
    } = useDic();

    const titleByNotificationType = createTitleByNotificationTypeFn({
        eventWorkflowEditLocation,
        eventSchedulerEditLocation,
    });

    const { onClickByNotificationType } = useNotificationActions();

    const [tableProps] = useFetchPage(
        (page, size, sort, filter) =>
            notificationsService
                .getNotifications({
                    page: page - 1,
                    size,
                    sort,
                    filter,
                })
                .then(response => {
                    const { content } = getData(response);
                    const contentWithMessages = content.map(notification => ({
                        ...notification,
                        message:
                            messageByNotificationType(notification, identity) ||
                            titleByNotificationType(notification),
                    }));
                    return {
                        ...response,
                        data: {
                            ...response.data,
                            content: contentWithMessages,
                        },
                    };
                }),
        [],
    );
    const typesQuery = useQueryLoadable(
        () => axiosService.get(`/api/notifications/types`).then(getData),
        [axiosService],
    );
    const severitiesQuery = useQueryLoadable(
        () => axiosService.get(`/api/notifications/severities`).then(getData),
        [axiosService],
    );

    const tableColumns = [
        {
            type: fieldTypes.OPTION,
            label: t("notifications-list.column.type"),
            name: "type",
            options: toOptions(typesQuery.loadable.valueMaybe()),
        },
        {
            type: fieldTypes.OPTION,
            label: t("notifications-list.column.status"),
            name: "severity",
            options: toOptions(severitiesQuery.loadable.valueMaybe()),
        },
        {
            type: fieldTypes.TEXT,
            label: t("notifications-list.column.message"),
            name: "message",
            canFilter: false,
            canSort: false,
            render: (text, record) => {
                const linkMessage = messageByNotificationType(record);
                return linkMessage || text;
            },
        },
        {
            type: fieldTypes.DATETIME,
            label: t("notifications-list.column.createdAt"),
            name: "createdAt",
            render: text => <FormattedDateTime>{text}</FormattedDateTime>,
        },
        {
            type: fieldTypes.DATETIME,
            label: t("notifications-list.column.notifiedAt"),
            name: "notifiedAt",
            render: text => <FormattedDateTime>{text}</FormattedDateTime>,
        },
        {
            type: fieldTypes.DATETIME,
            label: t("notifications-list.column.readAt"),
            name: "readAt",
            render: (text, record) =>
                record.readAt && <FormattedDateTime>{text}</FormattedDateTime>,
        },
    ];

    const actionMenu = useCallback(
        notification => (
            <ActionButton
                record={notification}
                items={[
                    {
                        title: t("general.detail"),
                        onClick: onClickByNotificationType(notification),
                    },
                ]}
            />
        ),
        [onClickByNotificationType],
    );

    return (
        <LoadableRenderer
            loadable={useComposeLoadablesMemoized([
                typesQuery.loadable,
                severitiesQuery.loadable,
            ])}
            hasValue={() => (
                <TableWithPreferencesManagement
                    actionMenu={actionMenu}
                    columns={tableColumns}
                    defaultSort={{
                        fieldName: "id",
                        sortDir: "descending",
                    }}
                    fixed
                    rowKey="id"
                    datasetSlicing="server"
                    preferencesType={preferencesType}
                    {...tableProps}
                />
            )}
        />
    );
};

NotificationsList.propTypes = {};

export default NotificationsList;
